<template>
  <div class="accessList common-page">
    <PocCardTable>
      <template slot="headerTitle">
        <span class="font21 colorB">{{ $t('dc:用户组管理') }}</span>
        <span class="padRL6 font21">/</span>
        <span class="font21">{{ $t('dc:权限列表') }}</span>
      </template>
      <template slot="headerGroup">
        <el-select
          v-model="pageConfig.projectId"
          filterable
          :clearable="true"
          :placeholder="$t('dc:请选择产品')"
          @change="queryApi"
          class=""
          style="margin-right: 10px; width: 240px"
        >
          <el-option
            v-for="item in productList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-input
          style="width: 260px; margin-right: 10px"
          show-word-limit
          v-model="pageConfig.keyword"
          :placeholder="$t('dc:请输入Code关键词')"
          :clearable="true"
          @keyup.enter.native="queryApi"
          @clear="queryApi"
        >
          <el-button
            class="btn-black"
            slot="append"
            @click="queryApi"
            icon="el-icon-search"
          ></el-button>
        </el-input>
        <el-button class="btn-black" type="info" @click="onAdd">{{ $t('dc:新增权限') }}</el-button>
        <!-- <el-button type="primary" icon="el-icon-refresh">{{$t('dc:刷新')}}</el-button> -->
      </template>
      <template slot="queryList"> </template>
      <PocTable
        v-loading="loading"
        :data="tableData"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        :border="false"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
      >
        <el-table-column
          v-for="item in columnOptions"
          :key="item.key"
          :prop="item.chlidren ? null : item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sorter="item.sortable"
          :width="item.key === 'no' ? 60 : null"
          :min-width="item.width"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="item.key === 'accessCode'">
              <span>{{ scope.row[item.key] | accessCodeListOne }}</span
              ><span style="font-weight: 700">{{ scope.row[item.key] | accessCodeListTwo }}</span>
            </span>
            <span v-else>{{ scope.row[item.key] || scope.$index + 1 }}</span>
          </template>
          <el-table-column
            v-for="chlid in item.chlidren"
            v-if="chlid.visible"
            :key="chlid.key"
            :prop="chlid.key"
            :label="chlid.name"
            :fixed="chlid.fixed"
            :sorter="chlid.sortable"
            :min-width="item.width"
            show-overflow-tooltip
          >
          </el-table-column>
        </el-table-column>

        <el-table-column :label="$t('dc:操作')" width="120" fixed="right" align="center">
          <template slot-scope="scope">
            <div class="action-btn-group">
              <el-tooltip effect="dark" :content="$t('dc:编辑')" placement="top-start">
                <i
                  class="poc-icon-info poc-size12 iconfont icon-oneui-contract-edit padding12 nicon"
                  @click="showEdit(scope.row)"
                ></i>
              </el-tooltip>
              <el-tooltip effect="dark" :content="$t('dc:分配')" placement="top">
                <i
                  class="poc-icon-info poc-size12 iconfont icon-oneui-contract-distribution padding12 nicon"
                  @click="showAssign(scope.row)"
                ></i>
              </el-tooltip>

              <!-- <el-tooltip effect="dark" :content="scope.row.status === 0?$t('dc:禁用'):scope.row.status === 3?$t('dc:恢复'):$t('dc:驳回')" placement="top-start">
                <i v-if="scope.row.status === 0 "  :class="'icon-oneui-contract-disable'" class="poc-icon-info poc-size12 iconfont padding12 nicon" @click="forbid(scope.row)"></i>
                <i :class="'icon-oneui-reload'" class="poc-icon-info poc-size12 iconfont padding12 nicon" @click="recover(scope.row)"></i>
              </el-tooltip> -->

              <el-tooltip effect="dark" :content="$t('dc:删除')" placement="top-start">
                <i
                  class="poc-icon-info poc-size12 iconfont icon-oneui-delete padding12 nicon"
                  @click="delectAccess(scope.row)"
                ></i>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </PocTable>
    </PocCardTable>
    <AccessDialog
      v-if="showDialog"
      :visible.sync="showDialog"
      :accessobj="accessobj"
      @queryApi="queryApi"
    ></AccessDialog>
    <Forbid4Delete
      v-if="showAccDialog"
      :visible.sync="showAccDialog"
      :title="title"
      :content="content"
      @confrom="confrom"
    ></Forbid4Delete>
    <AssignDialog
      v-if="showAssigDialog"
      :visible.sync="showAssigDialog"
      :title="title"
      :content="content"
      :rowData="accessobj"
      @confrom="confrom"
    ></AssignDialog>
  </div>
</template>

<script>
import Forbid4Delete from '@/views/dialog/usuallyDialog'
import AccessDialog from '@/views/dialog/accessDialog'
import AssignDialog from '@/views/dialog/assignDialog'

export default {
  name: 'accessList',
  mixins: [$PCommon.TableMixin],
  components: {
    AccessDialog,
    Forbid4Delete,
    AssignDialog,
  },
  data() {
    return {
      accessName: '',
      title: '',
      content: '',
      pageConfig: {
        projectId: '',
        keyword: '',
      },
      productList: [],
      loading: false,
      showDialog: false,
      showAccDialog: false,
      showAssigDialog: false,
      accessobj: {},
      tableData: [],
      delRowData: {},
    }
  },
  computed: {
    columnConfig() {
      return [
        {
          key: 'accessId',
          name: 'No.',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: false, // 固定列的位置(left, right)
          width: 60, // 默认宽度，像素
        },
        {
          key: 'projectName',
          name: this.$t('dc:产品名称'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false,
          width: 80,
        },
        {
          key: 'accessName',
          name: this.$t('dc:权限名称'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 120,
        },
        {
          key: 'moduleName',
          name: this.$t('dc:模块'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 180,
        },
        {
          key: 'accessCode',
          name: this.$t('dc:编码'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 180,
        },
      ]
    },
    columnOptions() {
      return this.columnConfig.filter((n) => n.visible)
    },
  },
  filters: {
    accessCodeListOne(val) {
      let index = val.lastIndexOf('.')
      val = val.substring(0, index + 1)
      return val
    },
    accessCodeListTwo(val) {
      let index = val.lastIndexOf('.')
      val = val.substring(index + 1, val.length)
      return val
    },
  },
  created() {},
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (to.query.id) {
          this.pageConfig.projectId = this.$route.query.id
        } else this.pageConfig.projectId = ''
        this.initEvent()
      },
    },
  },
  mounted() {
    this.getProductList()
    this.initEvent()
  },
  methods: {
    async initEvent() {
      this.queryApi()
    },
    async queryApi() {
      this.loading = true
      const { status, data, msg } = await $Api.access.getAccessList(this.pageConfig)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.pageConfig.total = data.total
        this.tableData = data.list
      }
      this.loading = false
    },
    delectAccess(item) {
      this.delRowData = item
      this.title = this.$t('dc:删除')
      this.content = this.$t('dc:您确认删除此访问权限吗?')
      this.showAccDialog = true
    },
    async confrom() {
      this.deleteAccess()
    },
    async deleteAccess() {
      let { status, msg } = await $Api.access.delAccess(this.delRowData.accessId)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.initEvent()
        this.$message({
          showClose: true,
          message: msg || this.$t('dc:成功'),
          type: 'success',
        })
        this.showAccDialog = false
      }
    },
    async getProductList() {
      let { status, data } = await $Api.project.optionList()
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.productList = data
      }
    },
    onAdd() {
      this.accessobj = {}
      this.showDialog = true
    },
    showEdit(item) {
      this.showDialog = true
      this.accessobj = item
    },
    showAssign(item) {
      this.showAssigDialog = true
      this.accessobj = item
    },
    forbid() {
      this.title = this.$t('dc:禁止')
      this.content = this.$t('dc:您确认要禁止该用户吗?')
      this.showAccDialog = true
    },
    recover() {
      this.title = this.$t('dc:恢复')
      this.content = this.$t('dc:您确认要恢复该用户吗?')
      this.showAccDialog = true
    },
  },
}
</script>

<style lang="scss">
.accessList .poc-card-table .pcth-query-box .pcth-queryName {
  min-width: 0;
}
.accessList .padding12 {
  padding: 0 12px;
}
</style>
