<template>
  <div>
    <el-dialog
      :visible.sync="show"
      width="682px"
      top="10%"
      :close-on-click-modal="false"
      class="assig-dialog"
      :title="$t('dc:分配到组')"
    >
      <div style="text-align: center">
        <el-transfer
          style="text-align: left; display: inline-block"
          filterable
          v-model="groupAssignValue"
          :props="{
            key: 'id',
            label: 'name',
          }"
          :left-default-checked="leftGroupData"
          :right-default-checked="rightGroupData"
          :render-content="renderFunc"
          :filter-placeholder="$t('dc:请输入用户组名称')"
          :titles="[$t('dc:未分配'), $t('dc:已分配')]"
          :button-texts="[$t('dc:移除'), $t('dc:新增')]"
          :format="{
            noChecked: '${total}',
            hasChecked: '${checked}/${total}',
          }"
          @change="handleChange"
          :data="groupAllData"
        >
        </el-transfer>
      </div>

      <span slot="footer" style="text-align: right">
        <el-button @click="show = false">{{ $t('dc:取消') }}</el-button>
        <el-button class="btn-black" @click="submitShowGroupAssign">{{ $t('dc:确认') }}</el-button>
      </span>
    </el-dialog>
    <UsuallyDialog
      v-if="show4Dialog"
      :visible.sync="show4Dialog"
      :title="title"
      :className="classNameOne"
      :content="content"
      @confrom="confrom"
    ></UsuallyDialog>
  </div>
</template>

<script>
import config from '@/api/config'
import UsuallyDialog from '@/views/dialog/usuallyDialog'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'accessDialog',
  components: {
    UsuallyDialog,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    rowData: {},
    type: String,
  },
  data() {
    return {
      loading: false,
      show: this.visible,
      show4Dialog: false,
      classNameOne: 'other',
      title: this.$t('dc:提醒'),
      content: this.$t('dc:你确认当前分配吗？'),
      groupAllData: [],
      leftGroupData: [],
      rightGroupData: [],
      groupAssignValue: [],
      paramsData: {
        accessId: this.rowData.accessId,
        selectedGroup: [],
      },
      renderFunc(h, option) {
        return <span>{option.name}</span>
      },
    }
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v)
    },
    visible(v) {
      this.show = v
    },
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  mounted() {
    this.initEvent()
  },
  methods: {
    initEvent() {
      this.getAssignGroupData(this.rowData.accessId)
    },
    async getAssignGroupData(params) {
      const { status, data } = await $Api.access.getGroupOption(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.groupAllData = data.allGroup
        this.leftGroupData = data.notSelectGroup
        this.rightGroupData = data.selectedGroup
        if (data.selectedGroup.length > 0) {
          this.groupAssignValue = []
          data.selectedGroup.map((item) => {
            this.groupAssignValue.push(item.id)
          })
        }
      }
    },
    handleChange(value, direction, movedKeys) {},

    onCancel() {
      this.show = false
    },
    submitShowGroupAssign() {
      this.paramsData.selectedGroup = []
      this.show4Dialog = true
      let newArry = []
      this.groupAllData.map((x, i) => {
        this.groupAssignValue.map((y) => {
          if (x.id === y) newArry.push(x)
        })
      })
      this.paramsData.selectedGroup = newArry
    },
    confrom() {
      this.submitGroupAssign(this.paramsData)
    },
    async submitGroupAssign(params) {
      let { status } = await $Api.access.setAssignGroup(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.show4Dialog = false
        this.show = false
        this.$message({
          showClose: true,
          message: this.$t('dc:成功'),
          type: 'success',
        })
      }
    },
  },
}
</script>
<style lang="scss">
.marginB50 {
  margin-bottom: 50px !important;
}
</style>
